import { graphql, Link } from "gatsby"
import { Playground, store } from "graphql-playground-react"
import * as React from "react"
import Layout from "../../components/Layout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const FaqPage = ({ data, pageContext }) => {
  const faqData = data?.faqData?.nodes
  const faqCategories = [
    ...new Set(
      faqData.map((list: any) => {
        return list.relativeDirectory
      })
    ),
  ]
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) => {
    crumb.crumbLabel = crumb?.crumbLabel?.replaceAll("-", " ")
  })

  console.log("data", data)
  const faqFormatedData = []
  if (faqCategories && faqCategories.length > 0) {
    for (const cat of faqCategories) {
      const obj: any = {}
      const categoryName: any = cat
        .toString()
        .split("-")
        .map((text: any, index: number) =>
          index === 0
            ? text.charAt(0).toUpperCase() + text.slice(1)
            : " " + text.charAt(0).toUpperCase() + text.slice(1)
        )
      obj.category = categoryName
      obj.faqList = []
      const filterFaqCategoryWise = faqData.filter(
        (faq: any) => faq.relativeDirectory === cat
      )
      if (filterFaqCategoryWise) {
        // obj.faqList = filterFaqCategoryWise
        obj.faqList = filterFaqCategoryWise.filter((faq: any) => {
          return faq.name !== "index"
        })
        obj.categoryFile = filterFaqCategoryWise.filter((faq: any) => {
          return faq.name === "index"
        })
      }
      faqFormatedData.push(obj)
    }
    console.log("faqFormatedData", faqFormatedData)
  }
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      <h1 className="page-title">FAQ</h1>
      <h2 className="page-sub-title">How can we help you today?</h2>
      <h2 className="page-sub-title blue-color">Knowledge base</h2>
      {faqFormatedData && faqFormatedData.length > 0 && (
        <div className="faq-card">
          <div className="d-flex row">
            {faqFormatedData.map((data: any) => (
              <div className="column">
                <div className="content">
                  {data?.categoryFile.length == 0 && (
                    <h3 className="category">{data?.category}</h3>
                  )}
                  {data?.categoryFile.length > 0 && (
                    <Link
                      to={`/support/faq/${data?.categoryFile[0]?.childMdx?.slug}`}
                    >
                      <h3 className="category">{data?.category}</h3>
                    </Link>
                  )}

                  {data?.faqList && data?.faqList.length > 0 && (
                    <div className="faq-list faq-list-image">
                      {data?.faqList.map((list: any) => (
                        <div className="item">
                          <Link to={`/support/faq/${list?.childMdx?.slug}/`}>
                            {list?.childMdx?.frontmatter?.title}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default FaqPage
export const query = graphql`
  query faq {
    faqData: allFile(filter: { sourceInstanceName: { eq: "faq" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
            order
          }
          slug
        }
        relativeDirectory
        name
      }
    }
  }
`
